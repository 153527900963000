<template>
  <el-dialog
      title="添加员工"
      width="55%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 40px" ref="form">
      <el-row>
        <el-col :span="7">
          <el-form-item label="客户ID" style="display: none">
            <el-input readonly v-model="form.companyId"></el-input>
          </el-form-item>
          <el-form-item label="客户名">
            <el-input readonly v-model="form.company"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label="手机号">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="微信">
            <el-input v-model="form.weChat"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label="职位" prop="grade">
            <el-input v-model="form.grade"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="客服" prop="serviceArray">
            <el-select v-model="form.serviceId" placeholder="客服">
              <el-option
                  v-for="item in serviceArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="渠道" prop="sourceId">
            <el-select v-model="form.sourceId" placeholder="预约渠道" ref="shop">
              <el-option
                  v-for="item in sourceArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="21">
          <el-form-item label="备注">
            <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 4}"
                placeholder="请输入内容"
                v-model="form.remark">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addUserCheck()">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "user-edit",
  data() {
    return {
      form: {
        companyId:this.user.companyId,
        company:this.user.company,
        id: this.user.id,
        name: this.user.name,
        phone: this.user.phone,
        weChat: this.user.weChat,
        grade: this.user.grade,
        serviceId: this.user.serviceId,
        sourceId: this.user.sourceId,
        remark: this.user.remark,
        tenantCrop: localStorage.getItem("tenantCrop")
      },
      serviceArray: [],
      sourceArray: [],
      rules: {
        companyId: [
          {required: true, message: '请输入ID', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],

        grade: [
          {required: true, message: '请输入职位', trigger: 'blur'},
        ],
      },
      dialogState: this.state,
    }
  },
  props: {
    user: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    state: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  created() {
    this.queryServiceArray();
    this.querySourceArray();
  },
  methods: {
    addUserCheck: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.addUser();
        }
      })
    },
    //添加用户
    addUser: function () {
      let value = "是否修改员工:" + this.form.name
      this.$confirm(value, '修改员工:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/trainUser/updateTrainUser",
          params: this.form
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("修改成功!")
            let self = this;
            setTimeout(function () {
              self.close()
            }, 1500)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //查询客服
    queryServiceArray: function () {
      this.$selectUtils.queryServiceIds().then(response => {
        this.serviceArray = JSON.parse(response.data.data)
      })
    },
    //查询店铺
    querySourceArray: function () {
      this.$selectUtils.querySourceIds().then(response => {
        this.sourceArray = JSON.parse(response.data.data)
      })
    },
    close: function () {
      this.$emit("close", false);
    }
  }
}
</script>

<style scoped>

</style>